<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'logLog',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/log/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '浏览器',
          type: 'input',
          key: 'browser' /* records[0].browser */, // TODO
          cols: 8
        },
        {
          name: '引擎',
          type: 'input',
          key: 'engine',
          cols: 8
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'browser',
          title: '浏览器',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.browser.length - b.browser.length
        },
        {
          dataIndex: 'engine',
          title: '引擎',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.engine.length - b.engine.length
        },
        {
          dataIndex: 'createBy',
          title: '创建人',
          type: 'lt-100',
          sorter: (a, b) => a.createBy - b.createBy
        },
        {
          dataIndex: 'engineVersion',
          title: '引擎版本',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.engineVersion.length - b.engineVersion,
          length
        },
        {
          dataIndex: 'clientId',
          title: '客户端标示',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.clientId.length - b.clientId.length
        },
        {
          dataIndex: 'updateTime',
          title: '操作时间',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '详情',
                onClick: () => this.$router.push(`/monitoring/logLogDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/log/${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return []
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
